@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../fonts/HelveticaNeue.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
